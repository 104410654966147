import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';

const AboutMarket = ({ market, umaQuestion }) => {
  const { t } = useTranslation();
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div>
      {market && (
        <Box>
          <Heading>{t('market_page.about_market')}</Heading>
            <p>{renderHTML(t(`markets.${market.id}.about_market`))}</p>
        </Box>
      )}
      {market && market.resolution_source && umaQuestion!=null && (
        <Resource>
          <p>
            {t('market_page.resolution_source')}
            <a href={umaQuestion.url} 
              style={{
                display:'inline-block', 
                paddingLeft:5
              }} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {/* {t(`markets.${market.id}.resolution_source`)} */}
              {umaQuestion!=null && <Button appearance="primary">{t('market_page.uma_question')}</Button>}
            </a>
          </p>
        </Resource>
      )}
    </div>
  ) 
}

export default AboutMarket;

const Box = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;

  p{
    font-size:15px;
    margin-bottom:0px;
  }
`
const Resource = styled(Box)`
  padding: 10px 15px;
  margin-bottom: 20px;
  background-color: #fff;
`

const Heading = styled.h2`
  margin-bottom: 20px;
  font-size: 18px;
`
const ButtonStyle = styled.button`
  border-radius: 2px;
  color: ${theme.black};
  padding-right: .625rem;
  width: 100%;
  border: none;
  height: 3rem;
  text-align: right;
  font-size: 15px;
  line-height: 1;
  outline: none;
  background-color: rgba(0,0,0,.04);

  .price {
    opacity: 0.6;
    display: inline-block;
    padding-left: 2px;
  }

  .result {
    margin-top: .4375rem;
    font-size: 14px;
    display: none;
  }

  &.btn-yes {
    &.active {
      background-color: #05b16a;
      color: #fff;

      .result {
        display: block;
      }
    }
  }

  &.btn-no {
    &.active {
      background-color: #e04545;
      color: #fff;
      
      .result {
        display: block;
      }
    }

  }

  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;
