/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme from '../../../styles/theme';

const Tab = ({
  tabs,
  selected,
  setSelected,
  children,
  listItemWidth,
}) => {
  return (
    <TabArea>
      <List>
        {tabs && tabs.map((tab, idx) => {
          const active = (tab === selected ? 'active': '');
    
          return (
            <ListItem
              key={`${String(tab).toLowerCase()}_${idx}`}
              listItemWidth={listItemWidth}
            >
              <a className={active} onClick={() => setSelected(tab)}>{tab}</a>
            </ListItem>
          )
        })}
      </List>
      {children}
    </TabArea>
  )
}

export default Tab;

Tab.propTypes = {
  listItemWidth: PropTypes.number,
};

const TabArea = styled.div.attrs(props => ({
  className: 'tab-area',
  marginBottom: props.marginBottom || 0,
}))`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
`;

const ListItem = styled.li`
  margin-bottom: -1px;

  a{
    display: block;
    padding: .5rem 2rem;
    text-decoration: none;
    color: ${theme.mutedText};
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #d0d0d0;

      &.active {
        border-bottom-color: ${theme.darkBlue};
        color: ${theme.darkBlue}
      }
    }

  ${({ listItemWidth }) => css`
    flex-basis: ${listItemWidth}%;
    max-width: ${listItemWidth}%
  `}
`;
