import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';
import InputBoxWithMax from '../InputBoxWithMax/InputBoxWithMax';
import { amountBToS} from '../../helpers/utilities';
import { gsSetGlobalState, disableAsyncButton, showLoadingButton, getAndSetWalletBalances } from '../../helpers/global-state';
import { doWeNeedToGetAllowance, getCollateralBalance, getMaxApproval, isLoggedInMetamask, loadFixedProductMarketMaker , getChainBaseUrl} from '../../helpers/web3';
import { useToasts } from 'react-toast-notifications'
import Modal from '../../components/Modal/Modal';
import useModal from '../../hooks/useModal';
import configData from '../../config.json';
import { strToFixed, amountSToB } from '../../helpers/utilities'
import { fpmmMergeShares } from '../../helpers/fpmm'
import { useTranslation } from 'react-i18next';


const MergeShares = ({
  isShowing,
  toggleShow,
  globalState, 
  setGlobalState,
  userStats,
  setUserStats,
  market,
  setShowOverlay
}) => {
  const { t } = useTranslation();
  const [sharesToMerge, setSharesToMerge] = useState('');
  const [mergeSharesSuccessHash, setMergeSharesSuccessHash] = useState(false);
  const [showMergeSharesModel, setShowMergeSharesModel] = useState(false);
  const getMaxMergableShares = () => {
    if(userStats == null) return 0;

    return userStats.marketPositionBalances.reduce((accumulator, curr) => { return amountSToB(accumulator) < amountSToB(curr) ? accumulator : curr; });
  }
  const [maxMergableShares, setMaxMergableShares] = useState(getMaxMergableShares());
  useEffect(() => {
    setMaxMergableShares(getMaxMergableShares());
  }, [userStats])
  const actionName = 'merge_shares';
  const { addToast } = useToasts();
  const isUserLoggedIn = isLoggedInMetamask(globalState);
  const onMaxClicked  = (e) => {
    e.preventDefault();
    //ask them to connect if not connected 
    if(!isUserLoggedIn){
      addToast(
        t('connect_your_wallet_first'), 
        { appearance: "warning" }
      );
      return 0;
    }
    setSharesToMerge(amountSToB(maxMergableShares));
  }

  const validate = () => {
    if(sharesToMerge=="" || sharesToMerge==0){
      addToast(
        t('market_page.enter_shares_you_want_to_merge'), 
        { appearance: "error" }
      );
      return 0;
    }

    const maxMergable = amountSToB(maxMergableShares);
    if(sharesToMerge > maxMergable){
      addToast(
        t('market_page.trying_to_remove_more_than_you_have_merge_share'), 
        { appearance: "error" }
      );
      return 0;
    }

    return 1;
  }

  const beforeSend = () => {
    setShowOverlay(true);
  }

  const afterSend = () => {
    setSharesToMerge("")
    setShowOverlay(false);
  }

  const onMergeSharesError = (error) => {
    addToast(
      error.message, 
      { appearance: "error" }
    );  
    afterSend();
  }

  const onTransactionHash = (hash) => {
    setMergeSharesSuccessHash(hash);
    afterSend();
  }

  const onConfirmation = (confirmationNumber, receipt) => {
    if(confirmationNumber==configData.SHOW_RESULT_AFTER_NTH_CONFIRMATION){
      addToast(
        t('market_page.shares_merged_and_position_updated'), 
        { appearance: "success" }
      );
      getAndSetWalletBalances(globalState, setGlobalState)
      // setUserStats();
    }
  }

  const mergeShares = async () => {
    try{
      const from = globalState.currentAddress;
      const sharesToMergeS = amountBToS(sharesToMerge);
      const argMergePositions = {
        globalState, 
        market, 
        from, 
        sharesToMerge: sharesToMergeS,
        onConfirmation,
        onError : onMergeSharesError,
        onTransactionHash
      }

      await fpmmMergeShares(argMergePositions);
    }catch(error){
      throw error;
    }
  }

  const validateAndMerge = async (event) => {
    event.preventDefault();

    if(!isUserLoggedIn){
      addToast(
        t('connect_your_wallet_first'), 
        { appearance: "warning" }
      );
      return 0;
    }

    if(!validate()) return 0;


    setShowMergeSharesModel(true)
  }

  const proceedWithMergeShares = async(event) => {
    event.preventDefault();
    setShowMergeSharesModel(false)
    beforeSend();
    const isValid = validate();
    if(isValid){
      try{
        await mergeShares();
      }catch(error){
        afterSend()  
      }
    }else{
      afterSend()
    }
  }

  const onSharesToMergeChange = (event) => {
    const inputSharesToMerge = event.target.value;
    const userLiquidityShares = amountSToB(maxMergableShares)
    if(isUserLoggedIn && inputSharesToMerge > userLiquidityShares) return 0;

    setSharesToMerge(inputSharesToMerge)
  }
  
  return (
    <div>
      {isShowing && (
        <LiquidityBox>
          <div className="text">{t('market_page.merge_shares')}</div>
          <div className="pbt" style={{fontSize:14, marginBottom:10}}>{t('market_page.shares_you_can_merge')}: {strToFixed(amountSToB(maxMergableShares))}</div>
          <InputBoxWithMax
            value={sharesToMerge}
            onChange={(e) => onSharesToMergeChange(e)}
            onClick={(e) => onMaxClicked(e)}
            maxItemName={t('share', {count: 2})}
          />
        
          <Group>
            <Button 
              appearance="primary" 
              onClick={validateAndMerge} 
              disabled={disableAsyncButton(globalState)}
            >
              {t('market_page.merge_shares')}
            </Button>
          </Group>
        </LiquidityBox>
      )}

      {!isShowing && (
        <ToggleWrap>
          <button onClick={toggleShow}>{t('market_page.merge_shares')}</button>
        </ToggleWrap>
      )}

      {showMergeSharesModel && (
        <Modal
          title={t('market_page.shares_merged_tandc_title')}
          align="center"
          close={() => setShowMergeSharesModel(false)}
        >
          <div>
            <p>
              {t('market_page.shares_merged_tandc_description')}
            </p>
            <div className="d-flex align-center justify-content-center">
              <div style={{padding:10}}>
                <Button appearance="primary" size="sm" onClick={(e) => proceedWithMergeShares(e)}>{t('market_page.i_agree')}</Button>
              </div>
              <div style={{padding:10}}>
                <Button appearance="danger" size="sm" onClick={() => setShowMergeSharesModel(false)}>{t('market_page.close_popup')}</Button>
              </div>
            </div>
          </div>          
         
        </Modal>
      )}

      {mergeSharesSuccessHash!='' && (
        <Modal
          title={t('transaction_hash')}
          align="center"
          close={() => setMergeSharesSuccessHash('')}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('transaction_hash')}: {mergeSharesSuccessHash}
            </p>
            <a href={getChainBaseUrl(globalState.chainId) + `/tx/${mergeSharesSuccessHash}`} rel="noreferrer" target="_blank">{t('visit_etherscan_to_view_transaction')}</a>
          </div>          
        </Modal>
      )}
    </div>
  )
};

export default MergeShares;

const LiquidityBox = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;


  .text {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Group = styled.div`
  margin-top: 18px;
  margin-bottom: 5px;
`;

const ToggleWrap = styled.div`
  text-align: center;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;

  button {
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    text-decoration: underline;
    padding: .5em;
    background-color: transparent;

    &:active,
    &:focus {
      outline: none;
    }
  }
`;
