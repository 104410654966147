import React, {useEffect} from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { useTranslation } from 'react-i18next';

const InputBoxWithMax = ({ value, onChange, onClick, maxItemName = 'USDC' }) => {
  const { t } = useTranslation();

  return (
    <InputBox>
      <input
        className="form-input"
        type="number"
        placeholder="0"
        min="0"
        value={value}
        onChange={onChange}
        onWheel={() => document.activeElement.blur()}
      />
      <span className="maxString">{maxItemName}<span>|</span><button onClick={onClick}>{t('max')}</button></span>
    </InputBox>
  )
}

export default InputBoxWithMax;

const InputBox = styled.div`
  position: relative;

  .form-input {
    line-height: 1.4375;
    border: 1px solid #ccc;
    padding: .5625rem 110px .5625rem 10px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid ${theme.darkBlue};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  span.maxString {
    display: block;
    position: absolute;
    padding: 0 4px;
    top: 12px;
    right: 2px;
    background-color: #fff;
    font-size:14px;

    span{
      display:inline-block;
      padding-left:3px;
      padding-right:3px;
    }

    button{
      color: ${theme.darkBlue};
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
      padding:0 8px 0 0;
      font-size:14px;
    }
  }
`