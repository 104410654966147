import configData from '../config.json';
import { isConditionalTokenApprovedForAll } from '../helpers/staking';

export const authenticationService = {
    isRestrictedCountry,
    setLocalStorageIsRestrictedCountry,
    getLocalStorageIsCountryRestricted,
    isCountryRestricted,
};

// async function isRestrictedCountry(){
    
//     // const response = await fetch('https://cloudflare-quic.com/b/headers');
//     // const jsonRes = await response.json();
//     // const countryISOCode = jsonRes.headers['Cf-Ipcountry'];

//     const response = await fetch(`https://api.geoapify.com/v1/ipinfo?apiKey=4abfda91434a4172b3f81ed049eac716`);
//     const jsonRes = await response.json();
//     const countryISOCode = jsonRes.country.iso_code;
//     return configData.restricted_countries.includes(countryISOCode)===true ? 1 : 0;
// }

async function isRestrictedCountry() {
    const url = 'https://ipinfo.io/json?token=b16ab244588e3b';  // Replace with your ipinfo.io API token
    
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch IP details.');
        }
        const jsonRes = await response.json();
        const countryISOCode = jsonRes.country; // Country ISO code (e.g., US, IN, etc.)

        // Assuming 'configData.restricted_countries' is defined somewhere
        return configData.restricted_countries.includes(countryISOCode) ? 1 : 0;
    } catch (error) {
        console.error('Error:', error);
        return 0; // Return 0 in case of error
    }
}



async function setLocalStorageIsRestrictedCountry() {
    localStorage.removeItem('isRestrictedCountry')
    const isCountryRestricted = await isRestrictedCountry();
    localStorage.setItem('isRestrictedCountry', isCountryRestricted)
}

function getLocalStorageIsCountryRestricted() {
    return localStorage.getItem('isRestrictedCountry');
}

function isCountryRestricted(){
    return getLocalStorageIsCountryRestricted()==1 ? 1 : 0;
}
