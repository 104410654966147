import React, { useState, useEffect, useRef } from 'react';
import {  useLocation, useParams, useHistory} from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled, { css } from 'styled-components/macro';
import { useToasts } from 'react-toast-notifications'

import Tab from '../../components/UI/Tab/Tab';
import TabContent from '../../components/UI/Tab/TabContent';
import { BreakpointUpLarge } from '../../styles/Breakpoints';
import Container from '../../components/Container/Container';
import theme from '../../styles/theme';
import DetailsHeader from './DetailsHeader';
import CheckboxLine from '../../components/UI/Checkbox/CheckboxLine';
import AddLiquidity from '../../components/AddLiquidity/AddLiquidity';
import ResolveMarket from '../../components/ResolveMarket/ResolveMarket';
import RemoveLiquidity from '../../components/RemoveLiquidity/RemoveLiquidity';
import RedeemPositions from '../../components/RedeemPositions/RedeemPositions';
import ResolvedMarket from '../../components/ResolvedMarket/ResolvedMarket';
import MergeShares from '../../components/MergeShares/MergeShares';
import TokenRewards from '../../components/TokenRewards/TokenRewards';
import News from '../../components/News/News';
import AboutMarket from '../../components/AboutMarket/AboutMarket';
import BuySell from '../../components/BuySell/BuySell';
import { marketService } from '../../services/market.service';
import { strToFixed, amountSToB, amountBToS } from '../../helpers/utilities'
import { getMarketStatsForUser } from '../../helpers/fpmm'
import { getTradesForLineChart } from '../../helpers/market-trades'
import Overlay from '../../components/Modal/Overlay';
import { theGraphService } from '../../services/thegraph.service'
import { useTranslation } from 'react-i18next';
import { getWhitelistedMarkets } from '../../helpers/site-down';


const MarketDetails = (props) => {
  const { t, i18n } = useTranslation();
  const  {globalState, setGlobalState} = props;
  const { currentAddress } = props.globalState;
  const { permalink } = useParams();
  const history = useHistory(); // Using history from React Router v5
  const [marketPositionsLabel, setMarketPositionsLabel] = useState(t('market_page.market_positions'))
  const [winningsAndLossesLabel, setWinningsAndLossesLabel] = useState(t('market_page.winning_and_lossings'))
  const [liquidityPositionsLabel, setLiquidityPositionsLabel] = useState(t('market_page.liquidity_positions'))
  const [showLiquidity, setShowLiquidity] = useState(false);
  const [yes, setYes] = useState(true);
  const [no, setNo] = useState(true);
  const [market, setMarket] = useState(null);
  const [marketFromTheGraph, setMarketFromTheGraph] = useState(null);
  const { addToast } = useToasts();
  const isUserLoggedIn = () => { return globalState.currentAddress == '' ? 0 : 1};
  const [userStats, setUserStats] = useState(null);
  const marketHasLiquidity = market && market.liquidity_measure > 0 ? true : false;
  const userHasLiquidity = userStats && userStats.liquidity && amountSToB(userStats.liquidity.liquidityShares) > 0 ? true : false;
  const [umaQuestion, setUmaQuestion] = useState(null);
  // const marketResolved = market && market.market_resolved==1 ? true : false;
  // const marketResolved = marketFromTheGraph && marketFromTheGraph.conditions[0].resolutionTimestamp!=null ? true : false;
  // const realityAnswerFinalized = marketFromTheGraph && marketFromTheGraph.question.isFinalized==true ? true : false;
  // const isFirstAnswerSubmitted = marketFromTheGraph && marketFromTheGraph.question.currentAnswer==null ? false : true;
  const [positionTabs, setPositionTabs] = useState(null);
  const lineChartHideShowLinesOnChange = (e, selectedOptionIndex) => {
    if(selectedOptionIndex==0){
      setHideLineChartFirstLine(!e.target.checked)
    }else{
      setHideLineChartSecondLine(!e.target.checked)
    }
  }

  useEffect(() => {
    // Get markets from session storage
    const storedMarkets = sessionStorage.getItem('whitelistedMarkets');
    console.log(storedMarkets)
    
    if (storedMarkets) {
      const parsedMarkets = JSON.parse(storedMarkets);

      // Check if the permalink exists in the stored markets
      const foundMarket = parsedMarkets.find(market => market.link === 'market/'+permalink);

      if (!foundMarket) {
        history.push('/'); // Redirect if permalink is not in the whitelisted markets
      }
    } else {
      // Redirect if no session data is available
      history.push('/');
    }
  }, [permalink, history]);

  const getPositionTabs = (marketFromTheGraph) => {
    let tabLabels;
    const mpLabel = t('market_page.market_positions')
    const lpLabel = t('market_page.liquidity_positions')
    const wlLabel = t('market_page.winning_and_lossings')
    setMarketPositionsLabel(mpLabel)
    setLiquidityPositionsLabel(lpLabel)
    setWinningsAndLossesLabel(wlLabel)

    const isMarketResolved = marketFromTheGraph && marketFromTheGraph.conditions[0].resolutionTimestamp!=null ? true : false;
    if(isMarketResolved)
      tabLabels = [wlLabel, lpLabel];
    else
      tabLabels = [mpLabel, lpLabel];
  
    return tabLabels;
  }
  const getResolvedOptionFromMarket = (marketFromTheGraph=null) => {
    return marketFromTheGraph && marketFromTheGraph.question.currentAnswerString;
  }
  const [selectedPositionTab, setSelectedPositionTab] = useState(null);
  const [realityAnswerFinalized, setRealityAnswerFinalized] = useState(marketFromTheGraph && marketFromTheGraph.question.isFinalized==true ? true : false);
  const [marketResolved, setMarketResolved] = useState(marketFromTheGraph && marketFromTheGraph.conditions[0].resolutionTimestamp!=null ? true : false);
  const [resolvedOption, setResolvedOption] = useState(getResolvedOptionFromMarket());
  const [isFirstAnswerSubmitted, setIsFirstAnswerSubmitted] = useState(marketFromTheGraph && marketFromTheGraph.question.currentAnswer==null ? false : true);
  const hasMarketPositions = isUserLoggedIn() && userStats && userStats.marketPositionBalances.some((e) => e > 0) && marketResolved ? true : false;
  const canMergeShares = isUserLoggedIn() && userStats && userStats.marketPositionBalances.every((e) => e > 0) ? true : false;
  const [showRemoveLiquidity, setShowRemoveLiquidity] = useState(false);
  const [showRedeemPositions, setShowRedeemPositions] = useState(false);
  const [showMergeShares, setShowMergeShares] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [lineChatDisplayingFor, setLineChatDisplayingFor] = useState('daily');
  const [hideLineChartFirstLine, setHideLineChartFirstLine] = useState(0);
  const [hideLineChartSecondLine, setHideLineChartSecondLine] = useState(0);
  const positionTabChangeHandler = (tab) => {
    setSelectedPositionTab(tab)
  }
  const getMarketPositionStats = (marketShareDetails) => {
    const shares = strToFixed(amountSToB(marketShareDetails.shares));
    const marginalPrice = strToFixed(marketShareDetails.marginal_price);
    const avgPrice = marketShareDetails.avg_price!=undefined ? strToFixed(marketShareDetails.avg_price) : marginalPrice;
    let profitPercent = 0;
    let profitAmount = 0;
    let lossPercent = 0;
    let lossAmount = 0;


    if(avgPrice > marginalPrice){
      lossPercent = ((avgPrice - marginalPrice) * 100)/avgPrice;
      lossAmount = avgPrice * shares - marginalPrice * shares;
    }else{
      profitPercent = ((marginalPrice - avgPrice) * 100)/avgPrice;
      profitAmount = marginalPrice * shares - avgPrice * shares;
    }
    
    const initialValue = avgPrice * shares;
    const currentValue = marginalPrice * shares;

    return {
      shares,
      avgPrice,
      marginalPrice,
      lossPercent,
      lossAmount,
      profitPercent,
      profitAmount,
      initialValue,
      currentValue
    }
  }

  const getMarketRow = (row) => {
    if(row===null) return (<tr key='no-market-postions-tr'><td colSpan="5">{t('market_page.no_market_positions')}</td></tr>);

    const marketPositionStats = getMarketPositionStats(row);
    const loss = marketPositionStats.lossPercent > 0 ? true : false;
    const profit = marketPositionStats.profitPercent > 0 ? true : false;
    return (<tr key={`market-postion-${row.option}`}>
      <td style={{minWidth:130, textAlign:'center'}}>
        <div style={{color:'var(--primary)'}}>{row.option}</div>
        <div style={{padding:3, backgroundColor:'#f2f2f2', borderRadius:2}}>{marketPositionStats.shares} {t('share', {count: 2})}</div>
      </td>
      <td>{`$${marketPositionStats.avgPrice}|$${marketPositionStats.marginalPrice}`}</td>
      {loss && 
        <td>{`$${marketPositionStats.lossAmount.toFixed(2)}|${marketPositionStats.lossPercent.toFixed(2)} `}{t('market_page.loss')}</td>
      }
      
      {!loss &&
        <td>{`$${marketPositionStats.profitAmount.toFixed(2)}|${marketPositionStats.profitPercent.toFixed(2)} `}{t('market_page.profit')}</td>
      }

      <td>{`$${marketPositionStats.initialValue.toFixed(2)}|$${marketPositionStats.currentValue.toFixed(2)}`}</td>
      <td>${marketPositionStats.shares}</td>
    </tr>)
  }

  const getUserMarketRows = (userStats, market) => {
    if(userStats===null) return getMarketRow(null);

    let rows = [];
    userStats.marketPositionBalances.forEach((e,i) => {
      if(e > 0){
        const marketOption = market.options[i];
        rows.push(getMarketRow({
          option : marketOption.option,
          marginal_price : marketOption.marginal_price,
          shares : e,
          avg_price: userStats.marketPositionAvgPrices[i]
        }))
      }
    });
    
    if(!rows.length) return getMarketRow(null);
    
    return rows;
  }

  const getUserWinningAndLossesRow = (row, marketFromTheGraph=null) => {
    if(row===null) return (<tr key='no-winning-and-lossing-tr'><td colSpan="5">{t('market_page.no_winning_or_lossings')}</td></tr>);
    const userSharesFixed = strToFixed(amountSToB(row.shares));
    const totalWinningOptions = marketFromTheGraph.conditions[0].payouts.filter((val, i)=>val>0).length;
    const isWinningOptions = marketFromTheGraph.conditions[0].payouts[parseInt(row.marketOption.index_set)-1]>0 ? true : false;
    // const totalWinningOptions = marketFromTheGraph.payouts.filter((val, i)=>val>0).length;
    // const isWinningOptions = marketFromTheGraph.payouts[parseInt(row.marketOption.index_set)-1]>0 ? true : false;
    const styleColor = isWinningOptions ? 'var(--success)' : 'var(--danger)';
    return (<tr key={`market-postion-${row.marketOption.option}`}>
      <td style={{minWidth:130, textAlign:'center'}}>
        <div style={{color:styleColor}}>{row.marketOption.option}</div>
        <div style={{padding:3, backgroundColor:'#f2f2f2', borderRadius:2}}>{userSharesFixed} Shares</div>
      </td>
      <td style={{color:styleColor}}>{isWinningOptions ? t('market_page.winning_and_lossings_winnings') : t('market_page.winning_and_lossings_loss')}</td>
      <td>${isWinningOptions===true ? userSharesFixed/totalWinningOptions : '0'}</td>
    </tr>)
  }

  const getUserwinningAndLossesRows = (userStats, market, marketFromTheGraph) => {
    let marketResolved = marketFromTheGraph && marketFromTheGraph.conditions[0].resolutionTimestamp!=null ? true : false;
    
    if(userStats===null || marketResolved!=true) return getUserWinningAndLossesRow(null);

    let rows = [];
    userStats.marketPositionBalances.forEach((e,i) => {
      if(e > 0){
        let marketOption = market.options[i];
        // const resolvedOption = getResolvedOptionFromMarket(market);
        rows.push(
          getUserWinningAndLossesRow(
            {
              marketOption,
              shares : e,
            },
            marketFromTheGraph
            // resolvedOption
          ));
      }
    });
    if(!rows.length) return getUserWinningAndLossesRow(null);
    
    return rows;
  }
  
  const getLiquidityRow = (userStats) => {
    if(userStats===null || userStats.liquidity.liquidityShares==0) return ((<tr key='no-liquidity-positions-row'><td colSpan="3">{t('market_page.no_liquidity_positions')}</td></tr>));
    const userLiquidityShares = userStats.liquidity.liquidityShares;
    
    const liquiditySharesB = strToFixed(amountSToB(userLiquidityShares));
    const feesWithdrawable = strToFixed(amountSToB(userStats.liquidity.feesWithdrawable));
    const userLiquidityShareValueInUsd = strToFixed(userStats.liquidity.userLiquidityShareValueInUsd);
    
    return (<tr key={`liquidity-postion-row`}>
        <>
          <td>{liquiditySharesB}</td>
          <td>${userLiquidityShareValueInUsd}</td>
          <td>${feesWithdrawable}</td>
        </>
    </tr>)
  }
  const [liquidityRow, setLiquidityRow] = useState(getLiquidityRow(userStats));

  const [marketPositionRows, setMarketPositionRows] = useState(getUserMarketRows(userStats, market));
  const [winningAndLossesRows, setwinningAndLossesRows] = useState(getUserwinningAndLossesRows(userStats, market, marketFromTheGraph));
  let lineChartRef = useRef(null);

  const [lineChartGraphData, setLineChartGraphData] = useState({});
  const [lineChartDayData, setLineChartDayData] = useState({});
  const [lineChartWeekData, setLineChartWeekData] = useState({});
  const [lineChartMonthData, setLineChartMonthData] = useState({});
  const [lineChartAllTimeData, setLineChartAllTimeData] = useState({});

  const choresAfterGettingTrades = (data) => {
    setLineChartDayData(data.dayTrades)
    setLineChartWeekData(data.weekTrades)
    setLineChartMonthData(data.monthTrades)
    setLineChartAllTimeData(data.allTimeTrades)
    setLineChartGraphData(data.dayTrades)
  }

  const setStatesPostStatsFetching = (market, marketFromTheGraph, umaQuestion) => {
    setMarket(market);
    setMarketFromTheGraph(marketFromTheGraph);
    setUmaQuestion(umaQuestion);
    setResolvedOption(getResolvedOptionFromMarket(marketFromTheGraph));
    setMarketResolved(marketFromTheGraph.conditions[0].resolutionTimestamp!=null ? true : false);
    setRealityAnswerFinalized(marketFromTheGraph.question.isFinalized);
    setIsFirstAnswerSubmitted(marketFromTheGraph.question.currentAnswer!=null ? true : false);
    const positionTabs = getPositionTabs(marketFromTheGraph);
    setPositionTabs(positionTabs);
    setSelectedPositionTab(positionTabs[0]);
  }

  const getAndSetUserStats = async () => {
    try {
      const fetchedMarket = await marketService.getMarketDetails(permalink)
      const marketFromTheGraph = await theGraphService.getMarket(fetchedMarket.fpmm_market_maker_address, fetchedMarket.question_id, globalState, fetchedMarket);
      const umaQuestion = marketFromTheGraph.question;
      setStatesPostStatsFetching(fetchedMarket, marketFromTheGraph, umaQuestion)
      
      getTradesForLineChart(fetchedMarket.fpmm_market_maker_address)
      .then(
        data => choresAfterGettingTrades(data),
        (error) => {
          
        }
        );
        
      if(isUserLoggedIn()){
        const userPositionsStats = await getMarketStatsForUser(globalState, fetchedMarket, globalState.currentAddress, isUserLoggedIn())
        setRealityAnswerFinalized(umaQuestion.isFinalized)
        setUserStats(userPositionsStats)
        setMarketPositionRows(getUserMarketRows(userPositionsStats, fetchedMarket))
        setwinningAndLossesRows(getUserwinningAndLossesRows(userPositionsStats, fetchedMarket, marketFromTheGraph))
        setLiquidityRow(getLiquidityRow(userPositionsStats))
        const userHasMarketPositions = userPositionsStats.marketPositionBalances.find((e)=> parseInt(e)>0)
        const userProvidedLiquidity = userPositionsStats.liquidityShares > 0;
        if(!userHasMarketPositions && userProvidedLiquidity){
          setSelectedPositionTab(liquidityPositionsLabel)
        }
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getAndSetUserStats();
  },[]);

  useEffect(() => {
    const pTabs = getPositionTabs(marketFromTheGraph)
    setPositionTabs(pTabs);
    setSelectedPositionTab(pTabs[0]);
  },[i18n.language]);

  useEffect(() => {
    if(isUserLoggedIn()){
      getAndSetUserStats()
    }
  }, [globalState])

  // Add Liquidity
  const toggleLiquidity = () => {
    setShowLiquidity(true);
  }

  const toggleRemoveLiquidity = () => {
    setShowRemoveLiquidity(true);
  }

  const toggleMergeShares = () => {
    setShowMergeShares(true);
  }

  const getOptionPrice = (index) => {
    return market!=null ? strToFixed(market.options[index].marginal_price) : '';
    
    // if(!marketResolved)
    // var resolvedOptionIndex = resolvedOption.index_set-1;
    // return index==resolvedOptionIndex ? 1 : 0;
  }

  const resetGraph = (resetFor) => {
    let graphRecords = [];
    switch (resetFor) {
      case 'daily':
        graphRecords = lineChartDayData;
        setLineChatDisplayingFor('daily');
        break;
      case 'week':
        graphRecords = lineChartWeekData;
        setLineChatDisplayingFor('week');
        break;
      case 'month':
        graphRecords = lineChartMonthData;
        setLineChatDisplayingFor('month');
        break;
      case 'all_time':
        graphRecords = lineChartAllTimeData
        setLineChatDisplayingFor('all_time');;
        break;
      default:
        graphRecords = lineChartDayData;
        setLineChatDisplayingFor('daily');
        break;
    }
    setLineChartGraphData(graphRecords);
  }

  function CustomTooltip({ payload, label, active }) {
    if (active && payload!=undefined) {
      if(payload[0]!=undefined && payload[1]!=undefined){
        const priceForOption1 = strToFixed(parseFloat(payload[0].value)/100)
        const priceForOption2 = strToFixed(parseFloat(payload[1].value)/100)
        return (
          <div className="custom-tooltip">
            <p>{label}</p>
            <p className="label">{`${market.options[0].option} : $${priceForOption1}`}</p>
            <p className="label">{`${market.options[1].option} : $${priceForOption2}`}</p>
          </div>
        );
      }
    }
  
    return null;
  }

  const LineGraph = (
    <ResponsiveContainer>
      <LineChart
        ref={lineChartRef}
        data={lineChartGraphData}
        margin={{
          top: 5, right: 0, left: 0, bottom: 0,
        }}
      > 
        <XAxis tick={{fontSize: 13}} dataKey="xAxisTitle" axisLine={false} tickLine={false}/>
        <YAxis tick={{fontSize: 13, padding: 4 }} type="number" unit="𝇍" domain={[0, 100]} axisLine={false} tickLine={false}/>
        {/* <Tooltip /> */}
        <Tooltip content={<CustomTooltip />}/>
        {/* activeDot={{ r: 7 }} connectNulls="true" */}
        <Line {...(hideLineChartFirstLine===true ? {hide:'1'} : {})} type="monotone" dataKey="prices[0]" stroke="#8884d8"  connectNulls="true"/>
        <Line {...(hideLineChartSecondLine===true ? {hide:'1'} : {})} type="monotone" dataKey="prices[1]" stroke="red"  connectNulls="true"/>
      </LineChart>
    </ResponsiveContainer>
  )

  return (
    <>
      <Page>
        <DetailsHeader market={market}/>
        <Container>
          <SectionCotent mb={35}>

            <LeftCol>
              {/* <GraphContainer>
                {LineGraph}
                <BtnGroup>
                  <button className={`btn btn-sm ${lineChatDisplayingFor=='daily' ? 'selected' : ''}`} onClick={() => resetGraph('daily')}>24{t('market_page.line_graph.hour_abbr')}</button>
                  <button className={`btn btn-sm ${lineChatDisplayingFor=='week' ? 'selected' : ''}`} onClick={() => resetGraph('week')}>7{t('market_page.line_graph.day_abbr')}</button>
                  <button className={`btn btn-sm ${lineChatDisplayingFor=='month' ? 'selected' : ''}`} onClick={() => resetGraph('month')}>30{t('market_page.line_graph.day_abbr')}</button>
                  <button className={`btn btn-sm ${lineChatDisplayingFor=='all_time' ? 'selected' : ''}`} onClick={() => resetGraph('all_time')}>{t('market_page.line_graph.all')}</button>
                </BtnGroup>
              </GraphContainer>
              <CheckBoxGid>
                <CheckboxLine
                  checked={!hideLineChartFirstLine}
                  // onChange={(e) => setYes(e.target.checked)}
                  onChange={(e) => lineChartHideShowLinesOnChange(e, 0)}
                  // onChange={(e) => hideLineChartFirstLine(!e.target.checked)}
                  // label={market && market.options[0].option}
                  label={market && t(`markets.${market.id}.options.${market.options[0].id}`)}
                  styleName="success"
                  amount={market && getOptionPrice(0)}
                />
                <CheckboxLine
                  checked={!hideLineChartSecondLine}
                  // onChange={(e) => setNo(e.target.checked)}
                  onChange={(e) => lineChartHideShowLinesOnChange(e, 1)}
                  // label={market && market.options[1].option}
                  label={market && t(`markets.${market.id}.options.${market.options[1].id}`)}
                  styleName="danger"
                  amount={market && getOptionPrice(1)}
                />
                
              </CheckBoxGid> */}

              {isUserLoggedIn()==1 && 
              
                <div style={{ backgroundColor: 'white'}}>
                  <Tab
                    tabs={positionTabs}
                    selected={selectedPositionTab}
                    setSelected={positionTabChangeHandler}
                  >
                    {!marketResolved && 
                      <TabContent isSelected={selectedPositionTab === marketPositionsLabel}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t('market_page.outcome')}</th>
                              <th>{t('market_page.price_avg_cur')}</th>
                              <th>{t('market_page.p_l_$_%')}</th>
                              <th>{t('market_page.value_init_cur')}</th>
                              <th>{t('market_page.max_payout')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {marketPositionRows}
                          </tbody>
                        </table>
                      </TabContent>
                    }

                    {marketResolved && 
                      <TabContent isSelected={selectedPositionTab == winningsAndLossesLabel}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t('market_page.winning_lossing_outcome')}</th>
                              <th>{t('market_page.winning_lossing_winning_losses')}</th>
                              <th>{t('market_page.winning_lossing_payout')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {winningAndLossesRows}
                          </tbody>
                        </table>
                      </TabContent>
                    }

                    <TabContent isSelected={selectedPositionTab === liquidityPositionsLabel}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t('market_page.liquidity_shares')}</th>
                            <th>{t('market_page.shares_value')}</th>
                            <th>{t('market_page.earned_fee')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {liquidityRow}
                        </tbody>
                      </table>
                    </TabContent>
                  </Tab>
                </div>
              }

              {market && <AboutMarket
                market={market} umaQuestion={umaQuestion}
              />}

              {market && market.news && <News
                mainTitle="Latest News"
                newsFeed={market.news}
              />}
            </LeftCol>

            <RightCol>
              { 
                marketHasLiquidity 
                && umaQuestion
                && !umaQuestion.isFinalized
                && 
                <BuySell 
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  market={market}
                  userStats={userStats}
                  setUserStats={() => getAndSetUserStats()}
                  setShowOverlay={setShowOverlay}
                />
              }

              { umaQuestion && !umaQuestion.isFinalized
                &&
                <AddLiquidity
                  isShowing={showLiquidity}
                  toggleShow={toggleLiquidity}
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                  market={market}
                  setUserStats={() => getAndSetUserStats()}
                  setShowOverlay={setShowOverlay}
                />
              }
              
              { marketResolved && resolvedOption!=null && 
                <ResolvedMarket resolvedOptionName={resolvedOption}/>
              }

              {!marketResolved && umaQuestion && umaQuestion.readyToBeResolved && 
                <ResolveMarket
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                  market={market}
                  setUserStats={() => getAndSetUserStats()}
                  setShowOverlay={setShowOverlay}
                  setSelectedPositionTab={setSelectedPositionTab}
                  winningsAndLossesLabel={winningsAndLossesLabel}
                />
              }

              {userHasLiquidity &&
                <RemoveLiquidity
                  isShowing={showRemoveLiquidity}
                  toggleShow={toggleRemoveLiquidity}
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                  userStats={userStats}
                  setUserStats={() => getAndSetUserStats()}
                  market={market}
                  setShowOverlay={setShowOverlay}
                />
              }

              {hasMarketPositions &&
                <RedeemPositions
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                  userStats={userStats}
                  setUserStats={() => getAndSetUserStats()}
                  market={market}
                  setShowOverlay={setShowOverlay}
                />
              }

              {canMergeShares &&
                <MergeShares
                  isShowing={showMergeShares}
                  toggleShow={toggleMergeShares}
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                  userStats={userStats}
                  setUserStats={() => getAndSetUserStats()}
                  market={market}
                  setShowOverlay={setShowOverlay}
                />
              }

              {/* <TokenRewards
                onClickClaim={() => null}
              /> */}

            </RightCol>
          </SectionCotent>
        </Container>
        {showOverlay && <Overlay/>}
      </Page>
    </>
  )
}

export default MarketDetails;

const Page = styled.div.attrs({className: 'page-market-details'})`
  padding-bottom: 50px;
  background-color: hsla(245, 70%, 85%, 70%);
`

const SectionCotent = styled.div`
  display: flex;
  flex-direction: column;

  ${BreakpointUpLarge} {
    flex-direction: row;
    ${({ mb }) => mb && css`
      margin-bottom: ${mb}px;
    `}
  }
`;

const LeftCol = styled.div`
  width: 100%;
  ${BreakpointUpLarge} {
    flex-basis: 687px;
    max-width: 687px;
  }
`;

const RightCol = styled.div`
  width: 100%;

  ${BreakpointUpLarge} {
    margin-left: 24px;
    min-width: 287px;
    width: 287px;
  }
`;

const GraphContainer = styled.div.attrs({ className: 'GraphContainer'})`
  position: relative;
  width: 100%;
  height: 429px;
  padding: 40px 12px 12px 0px;
  border: 1px solid #ada3e2;
  background-color: white;
`;


const CheckBoxGid = styled.div`
 display: grid;
 grid-gap: 30px;
 grid-template-columns: 1fr;
 padding-top: 20px;
 margin-bottom: 20px;

 ${BreakpointUpLarge} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const BtnGroup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-flex;
  vertical-align: middle;
  
  >.btn {
    position: relative;
    font-size: 13px;
    border-color: rgba(0,0,0,.08);
    flex: 1 1 auto;
    margin-left: 6px;
    background-color: rgb(239, 239, 239);
    
    &.selected{
      background-color: var(--primary);
      border-color: var(--primary);
      color: white;
    }

    &:focus{
      outline: 0;
    }
  }
`;