import React from 'react'
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const CheckboxLine = ({ amount, label, checked, styleName = 'default', ...props }) => {
  const getPrice = (price) => {
    if(price==null) return '';

    return isNaN(amount) ? 'N/A' : `$${amount}`
  }

  return (
    <Box>
      <Label>
        <InputWrap>
          <input type="checkbox" {...props} checked={checked} />
          <CheckMark className={`cbl-checkmark ${styleName}`} />
        </InputWrap>
        <Span>{label}</Span>
        <PriceSpan>{getPrice(amount)}</PriceSpan>
      </Label>
    </Box>
  )
}

export default CheckboxLine;

CheckboxLine.propTypes = {
  checked: PropTypes.bool.isRequired,
};

CheckboxLine.defaultProps = {
  checked: false,
};

// styles
const Box = styled.div`
  position: relative;
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #ada3e2;
  background-color: white;
`

const Label = styled.label`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #434a50;
  line-height: 1;
  cursor: pointer;
`;

const CheckMark = styled.span`
  position: relative;
  display: block;
  height: 25px;
  width: 25px;
  border: 1px solid #757575;
  background-color: white;
  transition: all 150ms;

  &.success {
    border: 1px solid #05b16a;
  }

  &.danger {
    border: 1px solid #e04545;
  }
 
  &:after {
    display: none;
    content: "";
    position: absolute;
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  } 
`;

const InputWrap = styled.div`
  height: 25px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .cbl-checkmark {
      background-color: #444;
      border-color: #444;

      &.success {
        border: 1px solid #05b16a;
        background-color: #05b16a;
      }

      &.danger {
        border: 1px solid #e04545;
        background-color: #e04545;
      }
    }

    &:checked ~ ${CheckMark}:after {
      display: block;
    }
  }
`

const Span = styled.span`
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  color: #757575;
`;

const PriceSpan = styled.span`
  color: inherit;
  font-weight: 400;
  font-size: 14px;
`;