import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import i18next for translations
import Navbar from './components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import SiteDown from './Pages/SiteDown/SiteDown';
import Footer from './components/Footer/Footer';
import MarketDetails from './Pages/MarketDetails/MarketDetails';
import PolybetDAOPage from './Pages/PolybetDAOPage';
import PolybetFormPage from './Pages/PolybetFormPage';
import PBTStakeUnstakePage from './Pages/PBTStakeUnstakePage';
import PolybetClaimReward from './Pages/PolybetClaimReward';
import configData from './config.json';
import { gsSetGlobalState } from './helpers/global-state';
import { authenticationService } from './services/authentication.service';
import { PrivateRoute } from './components/PrivateRoute';
import { ToastProvider } from 'react-toast-notifications'

const AppContent = ({ globalState, setGlobalState }) => {
  const { t } = useTranslation();  // Get translation function
  const location = useLocation();
  const [loading, setLoading] = useState(true);  // Track loading state
  const styles = {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',  // 100% of the viewport height
      textAlign: 'center',
      fontSize: '24px',
      color: '#333',  // You can change the color if needed
    },
  };
  
  useEffect(() => {
    // const onLoad = async () => {
    //   await authenticationService.setLocalStorageIsRestrictedCountry();
    //   const isCountryRestricted = authenticationService.isCountryRestricted();
    //   gsSetGlobalState(globalState, setGlobalState, { isCountryRestricted });
    //   setLoading(false);  // Set loading to false after country check is done
    // };
    // onLoad();
  }, [globalState, setGlobalState]);

  useEffect(() => {
    const onLoad = async () => {
      await authenticationService.setLocalStorageIsRestrictedCountry();
      const isCountryRestricted = authenticationService.isCountryRestricted();
      gsSetGlobalState(globalState, setGlobalState, { isCountryRestricted });
      setLoading(false);  // Set loading to false after country check is done
    };
    onLoad();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div>Loading...</div>
      </div>
    );
  }
  

  // Check if the country is restricted
  const isCountryRestricted = globalState.isCountryRestricted;

  if (isCountryRestricted) {
    // If the country is restricted, show a message instead of the routes
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <h1>{t('restricted_country_title_message')}</h1>
        <p>{t('restricted_country_message')}</p>
      </div>
    );
  }

  return (
    <>
      {location.pathname !== "/" && (
        <Navbar globalState={globalState} setGlobalState={setGlobalState} />
      )}
      <Switch>
        <Route exact path="/">
          <SiteDown globalState={globalState} setGlobalState={setGlobalState} />
        </Route>
        <Route exact path="/market/:permalink">
          <MarketDetails globalState={globalState} setGlobalState={setGlobalState} />
        </Route>
        {/* Catch-all route: Redirect all other URLs to / */}
        <Redirect to="/" />

        {/* <Route exact path="/DAO">
          <PolybetDAOPage globalState={globalState} />
        </Route>
        <Route path="/stake-unstake/:permalink?">
          <PolybetFormPage
            stakeType={configData.STAKE_TYPE_LP}
            globalState={globalState}
            setGlobalState={setGlobalState}
          />
        </Route>
        <Route path="/uniswap-stake-unstake">
          <PolybetFormPage
            stakeType={configData.STAKE_TYPE_UNISWAP}
            globalState={globalState}
            setGlobalState={setGlobalState}
          />
        </Route>
        <Route path="/pbt-stake-unstake">
          <PBTStakeUnstakePage
            stakeType={configData.STAKE_TYPE_PBT}
            globalState={globalState}
            setGlobalState={setGlobalState}
          />
        </Route>
        <PrivateRoute
          path="/claim-rewards/:companyName/:isMarket?"
          globalState={globalState}
          setGlobalState={setGlobalState}
          component={PolybetClaimReward}
        /> */}
      </Switch>
    </>
  );
};

function App() {
  const [globalState, setGlobalState] = useState({
    accounts: [],
    currentAddress: "",
    web3: null,
    balance: "",
    collateralBalance: "",
    collateralBalanceSmallestUnit: "",
    provider: null,
    connected: false,
    chainId: 0,
    installMetamask: false,
    loadedContracts: {
      collateralToken: null,
      conditionalToken: null,
      fixedProductMarketMaker: null,
      umaCtfAdapter: null,
    },
    submissionBeingProcessed: false,
    submittedFor: '',
    isCountryRestricted: 0,
    metamaskConnected: false,
  });

  return (
    <BrowserRouter>
      <AppContent globalState={globalState} setGlobalState={setGlobalState} />
    </BrowserRouter>
  );
}

export default App;
