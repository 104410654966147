import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../Container/Container'
import { BreakpointUpMedium } from '../../styles/Breakpoints';
import { useTranslation } from 'react-i18next';

// import svgLine from '../../assets/curly-lines.svg';
import ethereumLogo from '../../assets/ethereum-logo.svg';
import ButtonLink from '../../components/UI/ButtonLink/ButtonLink';

const Hero = ({ marginBottom }) => {
  const { t, i18n } = useTranslation();


  return (
    <Section marginBottom={marginBottom}>
      <Container noPaddingSmall>
        <EthereumLogo>
          <img src={ethereumLogo} alt="ethereum - logo" />
        </EthereumLogo>
        <ContentBox>
          <h1>{t('home.first_hero.title')}</h1>
          <p>{t('home.first_hero.description')}</p>
          {/* <ButtonsGroup>
            <div>
              <ButtonLink
                href="/somelink1"
                textSize={21}
              >
                Launch App
              </ButtonLink>
            </div>
          </ButtonsGroup> */}
        </ContentBox>
      </Container>
    </Section>
  )
}

export default Hero;

Hero.propTypes = {
  marginBottom: PropTypes.number
};

const Section = styled.section.attrs({ className: 'hero' })`
  position: relative;
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom}px;
  `}

  ${Container} {
    position: relative;
  }
`

const ContentBox = styled.div.attrs({ className: 'hero__content' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 230px;
  border-radius: 3px;
  padding: 40px 20px;
  background-position: top left;
  background-repeat: repeat;
  background-color: #4ea8c0;
  background-image: linear-gradient(180deg, #5094bf 0%, #29c4a9 100%);

  h1, p {
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 47px !important;
    margin-bottom: 20px;
  }

  p{
    font-weight: 700;
    font-size: 22px;
  }

  ${BreakpointUpMedium} {
    h1 {
      font-size: 40px;
      margin-bottom: 25px;
    }
    p{
      max-width: 510px;
      margin-bottom: 25px;
    }
  }
`

const ButtonsGroup = styled.div.attrs({ className: 'hero__buttons-group' })`
  display: flex;
  flex-direction: column;

  >div{
    padding: 12px;
  }

  ${BreakpointUpMedium} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

const EthereumLogo = styled.div`
  display: none;

  @media (min-width: 1070px) {
    display: block;
    position: absolute;
    width: 100px;
    top: 225px;
    left: -17%;
  }
`
