const markets = [
    {
      title: 'Will Donald Trump win the 2024 US Presidential Election?',
      link: 'market/Will-Donald-Trump-win-the-2024-US-Presidential-Election',
      whitelisted: [
        '0x5abd35f6aada34d66ef9dea222c4d2c429e73095',
        '0xcf3c440b908ed564245e79452e1e81a259d6f584',
        '0x0e8bde55ee9bca390e9b6cc9a1798922a3e150c5',
        '0xce196ac83e6bf55dc3d995df8bcfd8fbcac6096d',
        '0xfe260ebce57b3ed21ddec15cc8033c8284dcc44a',
        '0x4168ce15da77730396a2d35aeb15a5e40fe944bc',
        '0x27457b4b32532ed6800634d8f3fe0cec0da5a4fe',
        '0xf16e2d164c4416418bc7336fa178a72ae9133b64',
        '0x94e513dedfd6a8d17e8c09f0e6ce9841f71d4fba',
        '0xa2f386a3eb653229f6e9aaff5ede8e838560e2ece',
      ],
    },
    {
      title: 'Will Jake Paul beat Mike Tyson?',
      link: 'market/Will-Jake-Paul-beat-Mike-Tyson',
      whitelisted: [
        '0x4168ce15da77730396a2d35aeb15a5e40fe944bc',
        '0xce196ac83e6bf55dc3d995df8bcfd8fbcac6096d',
        '0x5abd35f6aada34d66ef9dea222c4d2c429e73095',
        '0x72f3b4fa773f76dc13483d1abd4b6e962f26d001',
        '0x27457b4b32532ed6800634d8f3fe0cec0da5a4fe',
        '0x125f505e246f684b75df27d823497ebb5a8458c7',
        '0xf16e2d164c4416418bc7336fa178a72ae9133b64',
        '0x5546363961e24de97b0b54f35b73f0875afc5fdc',
        '0x631b34cf9f08615a8653b2438a881fe38211dab4',
        '0xa2f386a3eb653229f6e9aaff5ede8e838560e2ec',
      ],
    },
    // {
    //   title: 'Will Trump claim involvement in $DJT?',
    //   link: 'market/will-trump-claim-involvement-in-DJT',
    //   whitelisted: [
    //     '0x27457b4b32532ed6800634d8f3fe0cec0da5a4fe',
    //     '0x5abd35f6aada34d66ef9dea222c4d2c429e73095',
    //     '0xfe260ebce57b3ed21ddec15cc8033c8284dcc44a',
    //     '0x4168ce15da77730396a2d35aeb15a5e40fe944bc',
    //   ],
    // },
    // {
    //   title: 'Will PolyBet airdrop PBT to Polymarket users before the EOY?',
    //   link: 'market/Will-PolyBet-airdrop-PBT-to-Polymarket-users-before-the-EOY',
    //   whitelisted: [
    //     '0x4168ce15da77730396a2d35aeb15a5e40fe944bc',
    //     '0x27457b4b32532ed6800634d8f3fe0cec0da5a4fe',
    //     '0x5abd35f6aada34d66ef9dea222c4d2c429e73095',
    //   ],
    // },
  ];

  // marketUtils.js

export function getWhitelistedMarkets(currentAddress) {
    return markets.filter((market) =>
        market.whitelisted.includes(currentAddress.toLowerCase())
    );
}
  
export function isUserWhitelisted(currentAddress) {
    return markets.some((market) =>
        market.whitelisted.includes(currentAddress.toLowerCase())
    );
}