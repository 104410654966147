import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import Hero from '../../components/Hero/Hero';
// import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
// import Checkbox from '../../components/UI/Checkbox/Checkbox';
// import FilterBox from '../../components/FilterBox/FilterBox';
import GridContainer from '../../components/Container/GridContainer';
import MarketCard from '../../components/MarketCard/MarketCard';
import CardGeneral from '../../components/UI/Cards/CardGeneral';
import CardHeader from '../../components/UI/Cards/CardHeader';
// import PolybetDAOPage from '../PolybetDAOPage';

import Rocket from '../../components/Rocket/Rocket';
// import Newsletter from '../../components/Newsletter/Newsletter';
import { authenticationService } from '../../services/authentication.service';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../styles/theme';


import polybet3dToken from '../../assets/PolyBet_3D_Token.svg';

// services
import { marketService } from '../../services/market.service';
import { useTranslation, Trans } from 'react-i18next';

// import useModal from '../../hooks/useModal';

am4core.useTheme(am4themes_animated);

const Home = (props) => {
  const { t, i18n} = useTranslation();
  const history = useHistory();
  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const  {globalState, setGlobalState} = props;
  const [cards, setCards] = useState();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  // const [showResolved, setShowResolved] = useState(false);
  // const [countryRestricted, setCountryRestricted] = useState();
  // const { open, openModal, closeModal } = useModal();
  // const [showModal, setShowModal] = useState(openModal);

  const chart = useRef(null);

  useEffect(() => {
    const onLoad = async () => {
      marketService.getPublishedMarkets()
      .then((data) => {
        setCards(data);
        if(authenticationService.isCountryRestricted()){
          // setShowCountryBlockedPopUp(1)
        }
      }, (error) => {
        console.log(error)
      })
    }
    onLoad();
  }, [])

  const cardClicked = (e, link) => {
    // if(authenticationService.isCountryRestricted()){
    //   setShowCountryBlockedPopUp(1)
    // }else{
    //   history.push(link);
    // }
    history.push(link);
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
      // Initialize the pieChart3D using am4core
      let pieChart = am4core.create("chartdiv", am4charts.PieChart3D);
      pieChart.radius = am4core.percent(72);
      
  
      // Altering hidden state to implement fade-in effect
      pieChart.hiddenState.properties.opacity = 0;
  
      const chartData = [
        { name: t('home.pie_chart.future_community_incentives'), value: 30, color: '#0088fe'},
        { name: t('home.pie_chart.polybet_foundation_and_team'), value: 20, color: '#ffbb28'},
        { name: t('home.pie_chart.polybet_market_liquidity_providers'), value: 20, color: '#00C49F'},
        { name: t('home.pie_chart.polybet_token_stakers'), value: 5, color: '#fc4e5b'},
        { name: t('home.pie_chart.polybet_winners'), value: 5, color: '#5752D1'},
        { name: t('home.pie_chart.uniswap_market_liquidity_providers'), value: 10, color: '#FF8042'},
        { name: t('home.pie_chart.pbt_claims'), value: 10, color: '#157196'},
      ];
  
      // inserting data to the pieChart instance 
      pieChart.data = chartData;
  
      // Initalizing a series and assigning values accordingly
      let series = pieChart.series.push(new am4charts.PieSeries3D());
      series.slices.template.propertyFields.fill = "color";
      series.labels.template.text = "{value.percent.formatNumber('#')}%";
      series.labels.template.wrap = true;
      series.labels.template.fontSize = 12;
      series.labels.template.fontFamily = 'Roboto Mono';
  
      series.dataFields.value = "value";
      series.dataFields.category = "name";
  
      series.tooltip.label.maxWidth = 250;
      series.tooltip.label.wrap = true;
      series.tooltip.label.fontFamily = 'Roboto Mono';
      series.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#')}%";

      
      if (windowSize[0] <= 1024) {
        series.slices.template.tooltipText = "";
        series.slices.template.alwaysShowTooltip = true;

        // Add slice click event
        var currentSlice;
        series.slices.template.events.on("hit", function(ev) {
          if (currentSlice) {
            currentSlice.tooltip.hide();
          }
          currentSlice = ev.target;
          currentSlice.tooltipText = "{category}: {value.percent.formatNumber('#.#')}% ({value.value})";
          currentSlice.invalidate();
          currentSlice.showTooltip();
        });

        // Set up page click event to close open tooltip
        am4core.getInteraction().body.events.on("hit", function(ev) {
          if (currentSlice) {
            currentSlice.tooltip.hide();
          }
        });
      }
      // Inserting chart instance into the DOm node
      chart.current = pieChart;
  
      // Cleanup function
      return () => {
        pieChart.dispose();
      };
  }, [t, windowSize]);

  return (
    <>
      <Hero marginBottom={30} />
      <Rocket />

      {/* <FilterBox
        column1={
          <DropdownSelect
            selectLabel="FILTER BY CATEGORY"
            label="test"
            options={options}
            values={[]}
            placeholder="All"
            onChange={(value) => console.log(value)}
          />
        }
        column2={
          <DropdownSelect
            selectLabel="SORT BY"
            options={options}
            values={[]}
            placeholder="Volume"
            onChange={(value) => console.log(value)}
          />
        }
        column3={
          <div>
            <p style={{ marginBottom: 7, fontSize: 14}}>SHOW RESOLVED</p>
            <Checkbox
              label="Show Resolved"
              checked={showResolved}
              onChange={(e)=>  setShowResolved(e.target.checked)}
            />
          </div> 
        }
      /> */}

      {cards && cards.length > 0 && <CardHeader
        title={t('home.markets')}
        mb={0.1}
        ></CardHeader>
      }
      {/* <GridContainer marginBottom={40}> */}
        {cards && cards.map(item => (
          <MarketCard marginBottom={20} item={item} key={item.id} to={`market/${item.permalink}`} onMarketClick={cardClicked}/>
        ))}
      {/* </GridContainer> */}
      
      <CardGeneral
        title={t('home.second_hero.title')}
        mt="40"
      >
        <p>{t('home.second_hero.p1')}</p>
        <p>
          <Trans i18nKey="home.second_hero.p2">
            Users bet on their predictions by connecting to PolyBet using MetaMask or other Web 3 wallets. PolyBet users use <a href="https://polygonscan.com/address/0x3c499c542cef5e3811e1192ce70d8cc03d5c3359" target="_blank">USDC</a> to place bets. Once a user places a wager they receive outcome shares. After a market has been resolved winners can redeem their outcome shares for $1 per share while losers get $0.  
          </Trans>
        </p>
      </CardGeneral>

      <CardGeneral
        appearance="yellow"
        title={t('home.third_hero.title')}
        >
        <p>
          <Trans i18nKey="home.third_hero.p1">
          PolyBet is a prediction market platform which is dependable application with no single point of failure. PolyBet uses  Gnosis' <a href="https://docs.gnosis.io/conditionaltokens/docs/introduction1/"  target="_blank">conditional token framework</a> and <a href="https://github.com/gnosis/conditional-tokens-market-makers/tree/master/contracts"  target="_blank">fixed product market maker</a> contracts. PolyBet markets do not rely on a single party as the oracle and instead use <a href="https://uma.xyz/"  target="_blank">UMA</a> crowd sourced verification for decentralized market resolution.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="home.third_hero.p2">
           Users don’t need to rely on PolyBet to ensure the correct outcome of a market is reported. PolyBet leverages <a href="https://uma.xyz/"  target="_blank">UMA</a> to ensure that a market is always resolved correctly. Once the decentralized market resolution process has completed users that placed the correct bet redeem their winnings
          </Trans>
        </p>
      </CardGeneral>

      {/* <CardGeneral
        appearance="green"
        title={t('home.fourth_hero.title')}
      >
       <p>
        <Trans i18nKey="home.fourth_hero.p1">
          PolyBet is designed to be a community owned project that is a fair launch protocol where users are rewarded for using PolyBet.
        </Trans>
       </p>
       <p>
        <Trans i18nKey="home.fourth_hero.p2">
          The <a href="https://etherscan.io/address/0xa406844323f1603701e6ad95adc8a082213a68ce" class="sc-bYoBSM iwFJTX" target="_blank">PolyBet Token (PBT)</a> contract is <span style={{wordWrap: 'break-word'}}>0xa406844323F1603701E6ad95ADc8A082213a68ce</span><br/><br/>The PolyBet Token is on Ethereum Mainnet. The maximum amount of of PBT that can ever be minted is 1 billion PBT. The vast majority of the <a href="https://etherscan.io/address/0xa406844323f1603701e6ad95adc8a082213a68ce" class="sc-bYoBSM iwFJTX" target="_blank">PolyBet Token (PBT)</a> will be distributed to the PolyBet community. PolyBet users are rewarded with PBT for using the PolyBet platform.
        </Trans>
       </p>

        <ContentGrid>
          <div>
            <p>
              <Trans i18nKey="home.fourth_hero.p3">
                <b>Liquidity Providers(20%)</b> (LPs) 200,000,000 PBT - will be given to LPs who provide USDC to PolyBet's <LinkUnderlined to="/market/Will-Donald-Trump-win-the-2024-US-Presidential-Election">genesis market</LinkUnderlined>. PolyBet LPs enable trading and assume risk. To reward & incentivize these LPs who give the information market life the PolyBet Token will be distributed to PolyBet liquidity providers. 200,000,000 PBT will be distributed to PolyBet <LinkUnderlined to="/market/Will-Donald-Trump-win-the-2024-US-Presidential-Election">genesis market</LinkUnderlined> LPs.
              </Trans>
            </p>
          
            <p>
              <Trans i18nKey="home.fourth_hero.p4">
                <b>Uniswap Liquidity Providers(10%)</b> - 100,000,000 PBT - will be given to Uniswap LPs. To incentivize users to provide liquidity to the PBT market users that provide liquidity to the PBT/ETH pool on Uniswap can stake their Uniswap LP tokens to earn PBT rewards. 
              </Trans>
            </p>
          </div>
          <div>
            <div className="d-flex justify-content-center align-items-center" style={{height:'100%'}}>
              <div><img src={polybet3dToken} alt="Polybet 3d Token" /></div>
            </div>
          </div>
        </ContentGrid>

       <PieGrid>
        <div>
          <p>
            <Trans i18nKey="home.fourth_hero.p5">
              <b>PBT stakers(5%)</b> - 50,000,000 PBT - will be given to PBT stakers. <a href="https://etherscan.io/address/0xa406844323f1603701e6ad95adc8a082213a68ce"  target="_blank">PolyBet Token (PBT)</a> stakers will be rewarded for holding PBT in the form of additional PBT rewards.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="home.fourth_hero.winners">
              <b>PolyBet bet winners(5%)</b> - 50,000,000 PBT - will be given to bettors that bet correctly in the PolyBet <LinkUnderlined to="/market/Will-Donald-Trump-win-the-2024-US-Presidential-Election">genesis market</LinkUnderlined>. PBT will be distributed to bettors pro rata their share of the winnings after the <LinkUnderlined to="/market/Will-Donald-Trump-win-the-2024-US-Presidential-Election">genesis market</LinkUnderlined> has been resolved.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="home.fourth_hero.p6">
            <strong>Claims(10%)</strong> - 100,000,000 PBT - To expedite the growth of the PolyBet community PBT can be claimed by holders of various crypto communities.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="home.fourth_hero.p7">
              <strong>PolyBet team and foundation(20%)</strong> - 200,000,000 PBT - The PolyBet team will be rewarded with PBT and the foundation will ensure there is continued development of the platform.
            </Trans>
          </p>
          
        </div>
        
        <div className="d-flex justify-content-center align-items-center">
          <div id="chartdiv" style={{ width: "100%", height: "400px" }}></div>
        </div>
        </PieGrid>
        <p>
          <Trans i18nKey="home.fourth_hero.p8">
            <strong>Future Community Incentives(30%)</strong> - 300,000,000 PBT - The <LinkUnderlined to="/DAO#">PolyBetDAO</LinkUnderlined> will decide how to allocate the remaining PBT supply. It is recommended by the PolyBet Team that the remaining PBT should replenish rewards for PolyBet market liquidity providers, Uniswap liquidity providers, & PBT stakers. Everytime these rewards have been entirely dispursed the PolyBetDAO could replenish the rewards with half as much as the previous amount of PBT. The <LinkUnderlined to="/DAO#">PolyBetDAO</LinkUnderlined> will decide how the remaining tokens should be distributed amongst the PolyBet ecosystem to best grow the platform.
          </Trans>
        </p>
      </CardGeneral> */}

      {/* <PolybetDAOPage globalState={globalState} /> */}

      {/* <Newsletter /> */}
      {showCountryBlockedPopUp===1 && (
        <Modal
          title={t('restricted_country_message')}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('restricted_country_message')}
            </p>
          </div>          
        </Modal>
      )}
    </>
  )
}

export default Home;

const LinkUnderlined = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  color: ${theme.lightBlue};
  display: inline-block;
  padding: 6px 12px;
  padding-left: 0px;
  vertical-align: middle; 

  &:is(:hover, :focus-visible) {
    color: ${theme.darkBlue};
  }
`;


const PieGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  align-content: center;

  g[opacity="0.3"] {
    display: none;
  }

  @media (min-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 979px) {
    > div:nth-child(2) {
      order: 1;
    }
    > div:nth-child(1) {
      order: 2;
    }
  }
`
const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    > div:nth-child(2) {
      order: 1;
    }
    > div:nth-child(1) {
      order: 2;
    }
  }
`