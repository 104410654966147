import React from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';

const TokenRewards = ({ onClickClaim }) => {
  return (
    <RewardsBox>
      <div className="text">PolyBet Token Rewards</div>
      <div className="pbt">PBT: 000735</div>

      <Group>
          <Button appearance="primary" onClick={onClickClaim}>Claim</Button>
      </Group>
    </RewardsBox>
  )
}

export default TokenRewards;

const RewardsBox = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 2px;
  border: 1px solid #ada3e2;
  background-color: ${theme.light};
  margin-bottom: 20px;

  .text {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .pbt {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
`;

const Group = styled.div`
  margin-top: 18px;
  margin-bottom: 5px;
`;