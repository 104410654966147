import React, {useState} from 'react';
import styled from 'styled-components';
// import { BreakpointUpMedium } from '../../styles/Breakpoints';
import Button from '../UI/Button/Button';
import theme from '../../styles/theme';
import InputBoxWithMax from '../InputBoxWithMax/InputBoxWithMax';
import { strToFixed } from '../../helpers/utilities';
import { authenticationService } from '../../services/authentication.service';
import Modal from '../Modal/Modal';
import { useTranslation } from 'react-i18next';

const WidgetForm = ({
  selectedTab,
  onSubmit,
  refreshPrices,
  yesNoData,
  btnSelect,
  setBtnSelect,
  sharesInputValue,
  onChangeShareInput,
  submitText,
  globalState,
  setGlobalState,
  inputValue,
  onInputChange,
  onMaxClick,
  maxItemName,
  market,
  showStats,
  displayValues
}) => {
  const { t } = useTranslation();
  const [showCountryBlockedPopUp, setShowCountryBlockedPopUp] = useState(0);
  const isCountryRestricted = authenticationService.isCountryRestricted();
  const countryRestrictedBtnClicked = (e) => {
      setShowCountryBlockedPopUp(1)
  }

  return (
    <WidgetBox>
      <OutcomeLabel>
        <div className="text">{t('market_page.pick_outcome')} </div>
        {/* <div>
          <button onClick={refreshPrices}>
            <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
              <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M7.73213 0.677823C7.94589 0.451943 8.15965 0.226056 8.3764 0.0034647C8.37617 0.363713 8.37667 0.723979 8.37718 1.08424C8.37827 1.85796 8.37936 2.63167 8.37311 3.4052C8.14991 3.405 7.92669 3.4047 7.70348 3.40439C6.83332 3.40321 5.96312 3.40202 5.09316 3.40694C5.31937 3.16926 5.54802 2.93408 5.77667 2.6989C5.9553 2.51517 6.13392 2.33145 6.31138 2.14653C5.61291 1.39888 4.52621 1.11883 3.56751 1.37319C2.73857 1.58722 2.02092 2.21426 1.65807 3.02503C1.59432 3.31031 1.35092 3.5228 1.06017 3.5228C0.720897 3.5228 0.445813 3.23356 0.445813 2.87674C0.445813 2.77179 0.469781 2.67273 0.512022 2.5851L0.510314 2.58435C1.01774 1.33765 2.12228 0.38377 3.38346 0.100439C4.09202 -0.0633413 4.84224 -0.0264522 5.5328 0.208402C6.1323 0.412335 6.68452 0.765795 7.1347 1.22894C7.13665 1.23064 7.13871 1.23256 7.14087 1.23457C7.15558 1.2483 7.17463 1.26607 7.19144 1.24535C7.37284 1.05749 7.55248 0.867658 7.73213 0.677823ZM6.7582 5.29463C6.84176 5.0415 7.06982 4.85979 7.33825 4.85979C7.67745 4.85979 7.95242 5.14984 7.9524 5.5076C7.9524 5.64257 7.91328 5.76781 7.84637 5.87156C7.38478 6.93943 6.49252 7.78926 5.43721 8.16297C4.3374 8.56405 3.07674 8.43565 2.07169 7.81648C1.7566 7.62671 1.46925 7.38887 1.2102 7.12182C0.926719 7.41743 0.645133 7.71503 0.363546 8.01264C0.24316 8.13987 0.122773 8.26711 0.002238 8.39419C-0.000883342 7.25931 -0.000534487 6.12464 0.00201767 4.98976L0.0481707 4.98977C1.12895 4.99014 2.20989 4.9905 3.29064 4.98956C3.07287 5.21966 2.8517 5.44628 2.63054 5.67289C2.4448 5.86319 2.25907 6.05349 2.07536 6.24585C2.78646 7.00793 3.90006 7.27929 4.86949 7.00214C5.70069 6.77049 6.40683 6.11922 6.75772 5.29439L6.7582 5.29463Z" fill="black"></path>
            </svg>
            <span>Refresh Prices</span>
          </button>
        </div> */}
      </OutcomeLabel>
      <Fieldset>
        {market && market.options.map((item, idx) => {
          const active = (item.index_set-1 === btnSelect ? 'active': '');
          return (
            <div
              key={`${String(item.option).toLowerCase()}_${idx}`}
            > 
              <ButtonStyle 
                className={`${idx === 0 ? 'btn-yes' : 'btn-no'} ${active}`} 
                onClick={(e) => setBtnSelect(e, item.index_set-1)}
              >
                {/* <span>{item.option}</span> */}
                <span>{market && t(`markets.${market.id}.options.${item.id}`)}</span>
                <span className="price">{`$${strToFixed(item.marginal_price)}`}</span>
                {/* <div className="result">
                  <svg width="28" height="10" viewBox="0 0 28 10" fill="none">
                    <path opacity="0.4" d="M0.799805 8.20001H0.499805V8.50001H0.799805V8.20001ZM27.1998 8.20001L24.1998 6.46796V9.93206L27.1998 8.20001ZM0.499805 0V8.20001H1.0998V0H0.499805ZM0.799805 8.50001H24.4998V7.90001H0.799805V8.50001Z" fill="white"></path>
                  </svg>
                  <span>{strToFixed(item.marginal_price)}</span>
                </div> */}
              </ButtonStyle>
            </div>
          )
        })}
      </Fieldset>

      <HowLabel>
        <div className="text">{t('market_page.how_much')}</div>
        <InputBoxWithMax
            value={inputValue}
            onChange={(e) => onInputChange(e)}
            onClick={(e) => onMaxClick(e)}
            maxItemName={maxItemName}
        />
      </HowLabel>
      {selectedTab && selectedTab === t('market_page.buy') && showStats == 1 &&
        <Group>
            <Line>
              <div className="text-left">{t('market_page.your_avg_price')}:</div>
              <div className="text-right">{`$${displayValues.yourAvgPrice}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.estimated_shares_bought')}:</div>
              <div className="text-right">{`${displayValues.estimatedSharesBought}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.maximum_winnings')}:</div>
              <div className="text-right">{`$${displayValues.maxWinnings}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.max_return_on_investment')}:</div>
              <div className="text-right">{`${displayValues.maxReturnOnInvestment}`}%</div>
            </Line>
        </Group>
      }
      {selectedTab && selectedTab === t('market_page.sell') && showStats == 1 && 
        <Group>
            <Line>
              <div className="text-left">{t('market_page.actual_shares_sold')}:</div>
              <div className="text-right">{`${displayValues.sharesToBeSold}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.selling_avg_price')}:</div>
              <div className="text-right">{`$${displayValues.yourAvgPrice}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.selling_remaining_shares')}:</div>
              <div className="text-right">{`${displayValues.remainingShares}`}</div>
            </Line>
            <Line>
              <div className="text-left">{t('market_page.selling_amount_you_will_receive')}:</div>
              <div className="text-right">{`$${displayValues.youWillReceive}`}</div>
            </Line>
        </Group>
      }
      <Group>
      {isCountryRestricted==0 && <Button appearance="primary" onClick={(e) => onSubmit(e)}>{submitText}</Button>}
      {isCountryRestricted==1 && <Button appearance="danger" onClick={(e) => countryRestrictedBtnClicked(e)}>{t('unavailable')}</Button>}
      </Group>
      {showCountryBlockedPopUp===1 && (
        <Modal
          title={t('restricted_country_message')}
          align="center"
          close={() => setShowCountryBlockedPopUp(0)}
        >
          <div>
            <p style={{wordWrap:'break-word', wordBreak: 'break-all'}}>
              {t('restricted_country_message')}
            </p>
          </div>          
        </Modal>
      )}
    </WidgetBox>
  )
}

export default WidgetForm;

const WidgetBox = styled.div`
  padding-bottom: 5px;
`;

const FormLabel = styled.label`
  display: block;
  margin: 1rem 0 .4375rem;

  .text {
    font-weight: 500;
    font-size: 15px;
  }
`;

const OutcomeLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    font-size: .8333em;
    outline: none;
    font-size: .725rem;
    padding: .375rem;
    opacity: 50%;
    border: none;
    background: none;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 3px;

    span {
      display: inline-block;
      padding-left: 4px;
    }
  }
`;

const Fieldset = styled.fieldset`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 11px;
  grid-template-columns: repeat(2,1fr);
  border: none;
  padding: 0;
`;

const ButtonStyle = styled.button`
  border-radius: 2px;
  color: ${theme.black};
  padding-right: .625rem;
  width: 100%;
  border: none;
  height: 3rem;
  text-align: right;
  font-size: 15px;
  line-height: 1;
  outline: none;
  background-color: rgba(0,0,0,.04);

  .price {
    opacity: 0.6;
    display: inline-block;
    padding-left: 2px;
  }

  .result {
    margin-top: .4375rem;
    font-size: 14px;
    display: none;
  }

  &.btn-yes {
    &.active {
      background-color: #05b16a;
      color: #fff;

      .result {
        display: block;
      }
    }
  }

  &.btn-no {
    &.active {
      background-color: #e04545;
      color: #fff;
      
      .result {
        display: block;
      }
    }

  }

  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

const HowLabel = styled(FormLabel)`
  .text {
    margin-bottom: 6px;
  }
`

const InputBox = styled.div`
  position: relative;

  .form-input {
    line-height: 1.4375;
    border: 1px solid #ccc;
    padding: .5625rem .75rem .4375rem;
    width: 100%;
    border-radius: 2px;
    border: 1px solid ${theme.darkBlue};
  }

  span {
    display: block;
    position: absolute;
    padding: 0 .5rem;
    top: 6px;
    right: 2px;
    background-color: #fff;

    button{
      color: ${theme.darkBlue};
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`

const Group = styled.div.attrs(props => ({
  mt: props.mt || 18,
}))`
  margin-top: ${({ mt }) => mt}px;
`;

const Line = styled.div`
  /*display: flex;
  flex-wrap: wrap;*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #000;

  > div {
    flex: 1 1;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
  }

  .text-right {
    text-align: left;
    font-weight: 400;
    padding:2px;
    background-color:white;
    border:1px dotted #bbb;
    border-radius: 3px;
    margin-bottom:4px;
  }
`;


const TradButton = styled.button`
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    padding: .65rem 0;
    width: 100%;
    color: #157196;
    border-radius: 2px;
    border: 1px solid #FFDFB1;
    background-color: #FFDFB1;
`;