import React, {useEffect, useState} from 'react';
import Web3 from "web3";
import USDC from "../../abis/USDC.json"
import PBT from "../../abis/PBT.json"
import { amountSToB, reFormatHexAddress, weiToEther} from '../../helpers/utilities';
import configData from "../../config.json";
import styled from 'styled-components';
import theme from '../../styles/theme';
import ConditionalToken from "../../abis/ConditionalTokens.json"
import RealityEth from "../../abis/RealityEthV3_0.json"
import FixedProductMarketMakerFactory from "../../abis/FixedProductMarketMakerFactory.json"
import Button from '../UI/Button/Button';
import { getChainBaseUrl} from '../../helpers/web3';
import { isUserWhitelisted } from '../../helpers/site-down';

import { useHistory } from 'react-router-dom';


import { useTranslation } from 'react-i18next';

export const ConnectForSiteDown = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [installMetamask, setInstallMetamask] = useState(false);
    const {globalState, setGlobalState, onCallParentFunction, appearance = 'default'} = props;
    const [metamaskConnected, setMetamaskConnect] = useState(false);
    const [currentAccount, setCurrentAccount] = useState('');
    const [usdBalance, setUsdBalance] = useState(0);
    const [pbtBalance, setPbtBalance] = useState(0);
    const [chainId, setChainId] = useState(0);
    const [hasAccessToRedeem, setHasAccessToRedeem] = useState(1);

    const loadContracts = async (web3, chainId=0) => {
        // if(globalState.currentAddress!=""){
            let collateralToken = null;

            if(chainId==configData.OTHER_PAGES_CHAIN_ID)
                collateralToken = await new web3.eth.Contract(USDC, configData.addresses.collateralToken);

            let pbtToken = null;

            if(chainId==configData.DAO_PAGES_CHAIN_ID)
                pbtToken = await new web3.eth.Contract(PBT, configData.addresses.PbtTokenContract);

            const conditionalToken = await new web3.eth.Contract(ConditionalToken.abi, configData.addresses.conditionalToken);
            const realityEth = await new web3.eth.Contract(RealityEth, configData.addresses.realityEth);
            // const fixedProductMarketMakerFactory = await new web3.eth.Contract(FixedProductMarketMakerFactory.abi, configData.addresses.fixedProductMarketMakerFactory);
            const contracts =  {
                collateralToken: collateralToken, 
                conditionalToken: conditionalToken,
                pbtToken: pbtToken,
                realityEth
                // fixedProductMarketMaker: fixedProductMarketMakerFactory
            };
            return contracts;
        // }else{
        //     alert('does not enter')
        // }
    }

    const afterConnect = async (web3) => {
        const accounts = await web3.eth.getAccounts();
        
        if (accounts.length > 0) {
            try {
                let firstAccount = accounts[0];
        
                const chainId = await web3.eth.getChainId();
                const contracts = await loadContracts(web3, chainId);
        
                let collateralBalanceSmallestUnit = 0;
                let collateralBalance = 0;
                if (chainId === configData.OTHER_PAGES_CHAIN_ID) {
                    const collateralToken = await new web3.eth.Contract(USDC, configData.addresses.collateralToken);
                    collateralBalanceSmallestUnit = await collateralToken.methods.balanceOf(firstAccount).call({ from: firstAccount });
                    collateralBalance = amountSToB(collateralBalanceSmallestUnit);
                }
        
                let pbtBalanceSmallestUnit = 0;
                let pbtBalance = 0;
                if (chainId === configData.DAO_PAGES_CHAIN_ID) {
                    const pbtToken = await new web3.eth.Contract(PBT, configData.addresses.PbtTokenContract);
                    pbtBalanceSmallestUnit = await pbtToken.methods.balanceOf(firstAccount).call({ from: firstAccount });
                    pbtBalance = weiToEther(pbtBalanceSmallestUnit);
                }
        
                const weiBalance = await web3.eth.getBalance(firstAccount);
                let ethBal = await web3.utils.fromWei(weiBalance);
                let metamaskConnected = true;
        
                setMetamaskConnect(true);
                setCurrentAccount(firstAccount.toLowerCase());
                setUsdBalance(collateralBalance);
                setPbtBalance(pbtBalance);
                setChainId(chainId);
        
                setGlobalState({
                    ...globalState,
                    collateralBalance,
                    collateralBalanceSmallestUnit,
                    currentAddress: firstAccount.toLowerCase(),
                    web3: web3,
                    balance: ethBal,
                    chainId,
                    accounts,
                    metamaskConnected,
                    loadedContracts: {
                        ...contracts,
                    },
                });
                
                if(isUserWhitelisted(currentAccount)){
                    console.log('whitelisted')
                    // Navigate to /redeem
                    onCallParentFunction(1)
                    // history.push('/redeem');
                }else{
                    console.log('not whitelisted')
                    onCallParentFunction(2)
                    // setHasAccessToRedeem(0);
                }
            } catch (e) {
                console.log(e)
            }
        }else{
            const contracts = await loadContracts(web3);
            setGlobalState({
                ...globalState, 
                loadedContracts: {
                    ...contracts
                }
            });
        }
    }


    const registerProviderEvents = () => {
        window.ethereum.on('accountsChanged', (accounts) => {
            // Handle the new accounts, or lack thereof.
            // "accounts" will always be an array, but it can be empty.
            window.location.reload();
        });
        
        window.ethereum.on('chainChanged', (chainId) => {
            window.location.reload();
        });

        window.ethereum.on('disconnect', (error) => {
            if(globalState.metamaskConnected==true)
                window.location.reload();
        });
    }

    const connect = async (e) => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const web3 = new Web3(window.ethereum);
            try {
                // Request account access if needed
                await window.ethereum
                .request({ method: 'eth_requestAccounts', params: [] })
                await afterConnect(web3)
            } catch (error) {
                console.log(error)
            }
        }
        // return false;
    }

    const installMetamaskAlert =  (e) => {
        e.preventDefault();
        alert('Install Metamask')
    }

    useEffect(() => {
        if (typeof window.ethereum === 'undefined' || !window.ethereum.isMetaMask) {
            setInstallMetamask(true);
            setGlobalState({...globalState, installMetamask: true})
        }else{
            registerProviderEvents();

            const web3 = new Web3(window.ethereum);
            afterConnect(web3)
        }
    }, [globalState.chainId, globalState.collateralBalance, globalState.pbtBalance]);
  
    return (
        <>
            {installMetamask && (
            <p>
                Eligible users can{' '}
                <StyledAnchor
                rel="noopener noreferrer"
                className="connect-btnn"
                onClick={installMetamaskAlert}
                >
                click here
                </StyledAnchor>
                {' '}to redeem.
            </p>
            )}

            {(!installMetamask && metamaskConnected !== true) && (
            <p>
                Eligible users can{' '}
                <StyledAnchor
                className={`${appearance === 'staking' ? 'block' : ''}`}
                type="submit"
                onClick={connect}
                >
                click here
                </StyledAnchor>
                {' '}to redeem.
            </p>
            )}

            {!hasAccessToRedeem && (
                <p>Your connected account is not eligible to redeem.</p>
            )}


            

            {/* {(!installMetamask && metamaskConnected!=true) && <StyledAnchor className={`${appearance==='staking' ? 'block' : null}`} type="submit" onClick={connect}>click here</StyledAnchor>} */}
            {/* {metamaskConnected==true && 
                <>
                    {chainId==configData.OTHER_PAGES_CHAIN_ID && <USDCSpan>{usdBalance} <a href={getChainBaseUrl(globalState.chainId) + `/address/${configData.addresses.collateralToken}`} target="_blank">USDC</a></USDCSpan>}
                    {chainId==configData.DAO_PAGES_CHAIN_ID && <USDCSpan>{pbtBalance} <a href={getChainBaseUrl(globalState.chainId) + `/address/${configData.addresses.PbtTokenContract}`} target="_blank">PBT</a></USDCSpan>}
                    <AddressSpan>{reFormatHexAddress(currentAccount)}</AddressSpan>
                </>
            } */}
            {/* {(!installMetamask && globalState.currentAddress=="") && <button className={`${appearance==='staking' ? 'block' : null} connect-btn`} type="submit" onClick={connect}>{t('connect_wallet')}</button>}
            {globalState.currentAddress!= "" && 
                <>
                    {globalState.chainId===configData.OTHER_PAGES_CHAIN_ID && <USDCSpan>{globalState.collateralBalance} USDC</USDCSpan>}
                    <AddressSpan>{reFormatHexAddress(globalState.currentAddress)}</AddressSpan>
                </>
            } */}
        </>
    );
};


const StyledAnchor = styled.a`
  display: inline-block; /* Ensures consistent inline behavior */
  color: #4CAF50; /* Text color */
  font-weight: bold; /* Bold text */
  text-decoration: none; /* Removes underline */
  cursor: pointer; /* Ensures the hand icon on hover */


  &:hover {
    color: #45a049; /* Change color on hover */
    text-decoration: underline; /* Optional: underline on hover */
  }

  &:focus {
    outline: none; /* Removes focus outline */
  }

  &:active {
    color: #3e8e41; /* Slightly darker color when active */
  }
`;

const AddressSpan = styled.span`
  display: inline-block;
  font-size: 13px;
  color: #444;
  text-align: center;
  vertical-align: middle;
  padding: 6px 12px;
  border-radius: 9px;
  background-color: #fafafa;
  font-weight:700;
`;

const USDCSpan = styled(AddressSpan)`
  background-color: #eee;
  border-top-right-radius:0px;
  border-bottom-right-radius:0px;
`;