import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import './i18n';
import App from './App';
import GlobalStyle from './styles/globalStyles';

const AppWrapper = () => {
  const location = useLocation();

  return (
    <React.StrictMode>
      {location.pathname !== "/" ? (
        <div className="page bgart">
          <ToastProvider>
            <App />
          </ToastProvider>
        </div>
      ) : (
        <ToastProvider>
          <App />
        </ToastProvider>
      )}
      <GlobalStyle />
    </React.StrictMode>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <AppWrapper />
  </BrowserRouter>,
  document.getElementById('root')
);