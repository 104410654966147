import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { getWhitelistedMarkets } from '../../helpers/site-down';
import {ConnectForSiteDown} from '../../components/ConnectForSiteDown/ConnectForSiteDown';

const CenteredText = (props) => {
  const  {globalState, setGlobalState} = props;
  const { currentAddress } = globalState;
  const WhitelistedMarkets = getWhitelistedMarkets(currentAddress);

  const [showMarkets, setShowMarkets] = useState(false);
  const [notEligible, setNotEligible] = useState(false);
  const handleConnectCallingShowMarket = (val) => {
    sessionStorage.removeItem('whitelistedMarkets');
    setShowMarkets(0)
    setNotEligible(0)
    
    if(val==1)
      setShowMarkets(true);
    else
      setNotEligible(true)

    const markets = getWhitelistedMarkets(currentAddress);
    if (markets.length > 0) {
      // Store markets in session storage
      sessionStorage.setItem('whitelistedMarkets', JSON.stringify(markets));
    } 
  };
  return (
    <StyledDiv>
      <div>
        <p>This site is not operational.</p>
        <ConnectForSiteDown globalState={globalState} setGlobalState={setGlobalState} onCallParentFunction={handleConnectCallingShowMarket} />
      </div>
     
      {showMarkets === true && (
        <>
          {/* Add the message outside the container */}
          {WhitelistedMarkets.length > 0 && (
            <p>Please find the eligible markets for redemption listed below:</p>
          )}
          <Container>
            <Content>
              {WhitelistedMarkets.length > 0 ? (
                <>
                  {WhitelistedMarkets.map((market, index) => (
                    <MarketItem key={index}>
                      <StyledLink to={`/${market.link}`}>{market.title}</StyledLink>
                    </MarketItem>
                  ))}
                </>
              ) : (
                <NoAccessMessage>Your connected account is not eligible to redeem.</NoAccessMessage>
              )}
            </Content>
          </Container>
        </>
      )}

      {notEligible===true && (
         <p>
         Your connected account is not eligible to redeem.
         </p>
      )}
    </StyledDiv>
  );
};

export default CenteredText;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column; /* Stack the text and button vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  font-size: 24px;
  line-height: 1.5;
  background-color: #f0f0f0;
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 18px;
  text-decoration: underline;
  color: ${theme.lightBlue};

  &:hover {
    color: ${theme.darkBlue};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  margin-top:20px;
  // height: 100vh;
  background-color: ${theme.lightGray};
`;

const Content = styled.div`
  text-align: center;
  padding: 20px;
  background-color: ${theme.white};
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const MarketItem = styled.div`
  margin-bottom: 16px;
`;


const NoAccessMessage = styled.div`
  font-size: 16px;
  color: ${theme.gray};
  margin-top: 20px;
`;

