import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Logo = ({ to, altText}) => {
  return (
    <SiteBrand
      to={to}
    >
      <svg width="160" height="24" viewBox="0 0 105 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>{altText}</title>
          <path d="M72.542 0.0810547V1.18605C71.823 0.461054 70.78 0.0810547 69.502 0.0810547H53.414V4.86405H49.727V0.0810547H43.85V6.60605C43.85 7.88505 44.226 8.92805 44.946 9.64505H35.382V0.0810547H29.505V1.18605C28.785 0.461054 27.741 0.0810547 26.466 0.0810547H19.293C17.606 0.0810547 16.374 0.714054 15.708 1.91705C15.04 0.714054 13.806 0.0810547 12.118 0.0810547H0.812988V15.5201H6.68799V10.7371H12.118C13.397 10.7371 14.441 10.3581 15.158 9.63505V11.3871C15.158 14.0121 16.665 15.5201 19.293 15.5201H26.466C27.743 15.5201 28.785 15.1391 29.505 14.4161V15.5201H55.155C56.434 15.5201 57.477 15.1391 58.195 14.4161V15.5201H69.502C70.78 15.5201 71.823 15.1391 72.542 14.4161V15.5201H87.981V5.95805H91.669V15.5211H97.544V5.95805H98.911L104.577 0.292055L104.786 0.0810547H72.542ZM98.81 5.71205H97.298V15.2751H91.915V5.71205H87.734V15.2751H72.788V13.7521L72.569 14.0181C71.899 14.8421 70.84 15.2761 69.502 15.2761H58.442V13.7511L58.223 14.0191C57.555 14.8431 56.494 15.2771 55.155 15.2771H41.774L45.844 11.2071L46.157 10.9121L46.33 10.9021L46.326 10.7931H45.903L41.174 15.2771H29.751V13.7541L29.533 14.0201C28.864 14.8441 27.803 15.2781 26.466 15.2781H19.293C16.821 15.2781 15.403 13.8621 15.403 11.3911V8.97105L15.184 9.23905C14.518 10.0611 13.457 10.4971 12.118 10.4971H6.44299V15.2781H1.05899V0.327055H12.119C13.804 0.327055 15.007 0.987055 15.597 2.24105L15.709 2.47505L15.82 2.24105C16.409 0.987055 17.609 0.327055 19.294 0.327055H26.467C27.804 0.327055 28.863 0.761055 29.534 1.58505L29.752 1.85305V0.327055H35.136V9.89105H45.61L45.342 9.67405C44.526 9.00605 44.095 7.94505 44.095 6.60605V0.327055H49.481V5.10805H53.659V0.327055H69.502C70.838 0.327055 71.899 0.761055 72.569 1.58505L72.788 1.85305V0.327055H104.192L98.81 5.71205Z" fill="#157196"/>
          <path d="M41.0539 14.376H30.6489V1.22803H34.2359V10.791H44.6379L41.0539 14.376Z" fill="#157196"/>
          <path d="M54.5579 1.22803V6.00703H48.5799V1.22803H44.9929V6.60703C44.9929 8.59903 45.9909 9.59503 47.9839 9.59503H54.5569V10.79H47.3729H46.1019L42.4969 14.376H55.1539C57.1469 14.376 58.1429 13.38 58.1429 11.388V1.22803H54.5579Z" fill="#157196"/>
          <path d="M86.8369 1.22803V4.81403H73.6849V1.22803H86.8369ZM86.8369 6.00703V9.59503H73.6849V6.00703H86.8369ZM86.8369 10.79V14.376H73.6849V10.79H86.8369Z" fill="#157196"/>
          <path d="M88.033 1.22803H102.021L98.434 4.81403H96.399V14.377H92.813V4.81303H88.033V1.22803Z" fill="#157196"/>
          <path d="M12.118 1.22803H1.95801V14.376H5.54501V9.59503H12.118C14.112 9.59503 15.108 8.59903 15.108 6.60703V4.21403C15.108 2.22203 14.112 1.22803 12.118 1.22803ZM11.522 6.00703H5.54501V4.81303H11.523V6.00703H11.522Z" fill="#157196"/>
          <path d="M26.466 1.22803H19.293C17.299 1.22803 16.302 2.22203 16.302 4.21403V11.388C16.302 13.38 17.299 14.376 19.293 14.376H26.466C28.458 14.376 29.453 13.38 29.453 11.388V4.21403C29.453 2.22203 28.458 1.22803 26.466 1.22803ZM25.866 10.79H19.888V4.81303H25.866V10.79Z" fill="#157196"/>
          <path d="M72.492 5.48305V4.21305C72.492 2.22105 71.495 1.22705 69.502 1.22705H59.339V14.3751H69.502C71.495 14.3751 72.492 13.3791 72.492 11.3871V10.1921C72.464 8.82305 71.868 8.02605 70.699 7.79705C71.892 7.55205 72.492 6.78005 72.492 5.48305ZM68.905 9.60405V10.7901V10.7981H62.927V10.7901V9.60405V9.59405H68.905V9.60405ZM68.905 6.00705H62.927V4.81305H68.905V6.00705Z" fill="#157196"/>
          <path d="M20.749 5.67102V9.92702H25.007V5.67102H20.749ZM24.761 9.68302H20.995V5.91702H24.761V9.68302V9.68302Z" fill="#157196"/>
      </svg>
    </SiteBrand>
  )
}

export default Logo;

// styles

const SiteBrand = styled(Link)`
  display: block;
  margin-right: 5px;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
`