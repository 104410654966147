import React, { useEffect } from 'react';
import styled from 'styled-components';
import Container from '../../components/Container/Container';
import theme from '../../styles/theme';
import { getFormattedDate } from '../../helpers/utilities';
import { amountSToB } from '../../helpers/utilities'
import { useTranslation } from 'react-i18next';


const DetailsHeader = ({market}) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log(market)
  },[market]);
  return (
    <Container>
      <Header>
        <Ttitle>
          <img src={market && market.image_url} alt="" />
          <TextContent>
            <span className="category">{market && t(`markets.${market.id}.category`)}</span>
            <h1>{market && t(`markets.${market.id}.question`)}</h1>
          </TextContent>
        </Ttitle>
        <Aside>
          <AsideContent>
            {/* <Box>
              <span className="w-normal">{t('market_page.market_ends_on')}</span>
              <span className="w-bold">{market && getFormattedDate(market.market_ends_on)}</span>
            </Box>
            <Box>
              <span className="w-normal">{t('market_page.trade_volume')}</span>
              <span className="w-bold">{market && `$${(amountSToB(market.collateral_volume)).toFixed(2)}`}</span>
            </Box> 
            <Box>
              <span className="w-normal">{t('market_page.liquidity')}</span>
              <span className="w-bold">{market && `$${(amountSToB(market.liquidity_measure)).toFixed(2)}`}</span>
            </Box>
            */}
          </AsideContent>
        </Aside>
      </Header>
    </Container>
  )
}

export default DetailsHeader;

const Header = styled.header`
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(140, 180, 200, 0.80);
`;

const TextContent = styled.div``;

const Ttitle = styled.div`
  display: flex;

  img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    object-fit: cover;
  }

  ${TextContent} {
    padding-left: 16px;

    .category {
      font-size: 14px;
      color: #44423ec4;
    }
  }

  h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 20px;
    margin: 8px 25px 5px 0;
  }
`;

const Aside = styled.aside``;

const AsideContent = styled.div`
  display: flex;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 7rem;
  padding: 7px 12px;
  white-space: nowrap;
  /* border: 1px solid #718c8e;
  background-color: ${theme.light}; */
  border: 1px solid ${theme.darkBlue};
  background-color: ${theme.light};

  .w-normal {
    font-weight: 400;
    font-size: 12px;
    color: ${theme.mutedText};
  }

  .w-bold {
    font-weight: 500;
    margin: .2rem 0;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
`;
